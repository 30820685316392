@tailwind base;
@tailwind components;

body {
    font-family: 'Raleway', sans-serif;
    color: #363636;
    @apply h-screen;
    word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    @apply text-5xl;
}
h2 {
    @apply text-4xl;
}
h3 {
    @apply text-3xl;
}
h4 {
    @apply text-2xl;
}
h5 {
    @apply text-xl;
}
h6 {
    @apply text-base;
}

@media (min-width: 768px) {
    h1 {
        @apply text-6xl;
    }
    h2 {
        @apply text-5xl;
    }
    h3 {
        @apply text-4xl;
    }
    h4 {
        @apply text-3xl;
    }
    h5 {
        @apply text-2xl;
    }
    h6 {
        @apply text-xl;
    }
}

a,
button {
    @apply cursor-pointer;
}

.button {
    @apply bg-ctsystems;
    @apply text-white;
    @apply uppercase;
    @apply font-bold;
    @apply px-8;
    @apply py-4;
    @apply flex;
    @apply justify-center;
    @apply items-center;
}

.button.white {
    @apply bg-white;
    @apply text-ctsystems;
}

.button:disabled {
    @apply bg-gray-400;
    @apply text-white;
}

.input {
    @apply bg-white;
    @apply border;
    @apply border-gray-400;
    @apply rounded;
    @apply p-4;
    @apply block;
    @apply w-full;
    @apply appearance-none;
    @apply leading-normal;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

@tailwind utilities;
